<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width: 70%;display: flex;">
          <el-input
            class="hunt"
            v-model="input"
            placeholder="请输入标题"
            size="large"
            @input="orangutan"
			clearable 
          />
          <!-- 类型 -->
          <!-- <el-select class="hunt"  v-model="activity" placeholder="请选择类型" @change="numerical" clearable @clear="empty" size="large">
            <el-option label="直属机关工会" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="基层工会" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select> -->
          <!-- 是否启用 -->
          <!-- <el-select class="hunt"  v-model="values" placeholder="请选择状态" @change="numerical1" clearable @clear="empty" size="large">
            <el-option label="已发布" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="未发布" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select> -->
          <!-- 组织名称 -->
          <el-select
            class="hunt"
            @focus="closeIt"
            v-model="ability"
            placeholder="请选择组织名称"
            size="large"
            @click="multiple"
            clearable
            @change="numerical2"
            ref="selectIt"
          />
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <div>
          <el-button
            style="margin-left: 10px;width: 90px"
            type="primary"
			size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="review"
            >往期回顾</el-button
          >
        </div>
      </div>
      <div class="summation">
        <div class="hint">统计 : {{totality}}条</div>
        <div style="    font-size: 14px;color: #909399;">提示 : 外部工会管理与报名二维码功能适用于智慧工会会员体系之外场景使用，只支持二维码收款方式。</div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
      >
        <!-- 标题 -->
        <el-table-column
          fixed
          label="标题"
          prop="title"
          align="center"
          :show-overflow-tooltip='true'
          width="300"
        >
        </el-table-column>
        <!-- 所属组织 -->
        <el-table-column align="center" width="200" label="所属工会" :show-overflow-tooltip='true' prop="unionName" />
        <!-- 时间 -->
        <el-table-column
          label="创建时间"
          prop="createTime"
          align="center"
          :formatter="tiems"
          :show-overflow-tooltip='true'
          width="200"
        >
        </el-table-column>
        <!-- 报名时间 -->
        <el-table-column
          label="报名时间"
          prop="startTime,endTime"
          align="center"
          :formatter="reach"
          :show-overflow-tooltip='true'
          width="300"
        >
          <!-- <template #default="scope"> 
                {{scope.row.startTime}} - {{scope.row.endTime}} 
            </template> -->
        </el-table-column>
        <!-- 是否启用 -->
        <el-table-column align="center" label="状态" :show-overflow-tooltip='true' prop="state" width="100" :formatter="formatSex"/>
        <el-table-column label="操作" align="center" width="660">
          <template #default="scope">
            <el-button
              size="default"
              @click.stop="check(scope.row.childCustodyId)"
              class="bulur"
              ><el-icon class="cancel"> <Search /> </el-icon>查看报名</el-button
            >
            <el-button
              size="default"
              @click.stop="inventory(scope.row.childCustodyId)"
              class="green"
              ><el-icon class="cancel"> <Setting /> </el-icon>班次管理</el-button
            >
             <el-button
              size="default"
              @click.stop="exterior(scope.row.childCustodyId)"
              class="green"
              ><el-icon class="cancel"> <Setting /> </el-icon>外部工会管理</el-button
            >
             <el-button
              size="default"
              @click.stop="scanning(scope.row.childCustodyId)"
              class="bulur"
              ><img class="erwei" src="../assets/erwei.png" alt="">报名二维码</el-button
            >
            <el-button
              size="default"
              @click.stop="copyreader(scope.row.childCustodyId)"
              class="bulur"
              ><el-icon class="cancel"> <EditPen /> </el-icon>编辑</el-button
            >
            <!-- <el-button size="default" @click.stop="expurgate(scope.row.id)" class="red"
              ><el-icon class="cancel"> <Delete /> </el-icon>删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]" 
        :page-size="pagesize"
        :total="totality"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
          <el-button class="dsa" size="large" @click="remove=false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 所属组织弹窗 --> 
    <div>
      <s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
    <!-- 二维码弹窗 -->
     <el-dialog v-model="dimensional">
        <div class="arrow">
          <img class="imagess" :src="rocket" alt="">
          <div class="pussy">报名二维码</div>
        </div>
      </el-dialog> 
  </div>
</template>
<script>
import { Delete, EditPen, Search,Plus,Setting} from "@element-plus/icons-vue";
import { useRouter,useRoute  } from "vue-router";
import{ ref,onMounted,onActivated } from 'vue'
import { ZNapply,ZClist,FCdelete,ZNscanning } from '../utils/api'
import moments from 'moment'
import qs from 'qs'
import { ElMessage } from "element-plus";
export default {
  name:"ZNtrustee",
  setup() {
    // 二维码链接
    const rocket=ref('')
    // 查看报名二维码
    const dimensional=ref(false)
    const scanning=(id)=>{
      console.log(id)
      let data={
        childCustodyId:id
      }
      ZNscanning(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          dimensional.value=true
          rocket.value=res.data.data.registrationCodeUrl
        }else if (res.data.code!==0){
          ElMessage({
              message: res.data.msg,
              type: "warning",
              center: true,
            });
        }
      })
    }



    // 往期回顾跳转
    const review=()=>{
      router.push('/children')
    }


    const route = useRoute();
    // 搜索加载
    const formative=ref(false)
    // 判断类型
    let genre=(row, column, cellValue)=>{
      if (cellValue == 1) {
        return "直属机关工会";
      } else if (cellValue === 2) {
        return "基层工会";
      }
    }
    // 判断是否启用
    let formatSex = (row, column, cellValue) => {
      if (cellValue == 1) {
        return "已发布";
      } else if (cellValue === 2) {
        return "未发布";
      }
    };
    // 点击每一行跳转
    let unit=(row)=>{
      console.log(row)
      // sessionStorage.setItem('FCHD', row.id)
      // router.push('/FCdetails')
    }
    let selectIt=ref()
    // 下拉隐藏
    let closeIt=()=>{
      selectIt.value.blur();
    }
    // 删除确定
    let affirm=()=>{
      let data={
        id:modeid.value
      }
      FCdelete(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          ElMessage({
            message: '删除成功',
            type: "success",
            center: true,
          });
          remove.value=false
          if(input.value!==''|| ability.value!==''){
            search()
          }else{
            list()
          }
        }else{
          ElMessage({
            message: res.data.msg,
            type: "warning",
            center: true,
          });
          if(input.value!==''|| ability.value!==''){
            search()
          }else{
            list()
          }
          remove.value=false
        }
        console.log(res)
      })
    }
    // 删除弹窗
    let remove=ref(false)
    // 获取信息id
    let modeid=ref('')
    // 删除按钮
    let expurgate=(id)=>{
      remove.value=true
      modeid.value=id
      console.log(id)
    }
    // 会员姓名输入框改变
    // const orangutan=()=>{
    //   if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // 选择组织名称变化
    let numerical2=()=>{
      // if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
      //   list()
      // }
      Hcode.value=''
    }
    // let numerical1=()=>{
    //   if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // let numerical2=()=>{
    //   if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // 搜索按钮
    let search1=()=>{
      formative.value=true
      search()
    }
    let search=()=>{
      console.log(input.value)
      console.log(Hcode.value)
      // console.log(values.value)
      let data={
        limit:branches.value,
        page:currentPage.value,
        // isEnable:values.value,
        title:input.value,
        unionCode:Hcode.value,
        // type:activity.value
      }
      ZNapply(qs.stringify(data)).then((res)=>{
        formative.value=false
        // 列表总数
        totality.value=res.data.data.childCustodyList.totalCount
        // 列表数据
        filterTableData.value=res.data.data.childCustodyList.list
        if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        // console.log(res)
      })
    }
    // 类型
    // let activity=ref('')
    // 是否启用
    // let values=ref('')
    // 标题内容
    let input=ref('')
    // 搜索框弹窗
    // 获取code
    let Hcode=ref('')
    // 获取选项
    let ability=ref('')
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    let visible=ref(false)
    let multiple=()=>{
      visible.value=true
    }
    // 搜索框
    let texture=ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    // 获取所有组织列表
    let own=()=>{
      ZClist().then((res)=>{
        console.log(res)
        if(res.data.code==0){
          texture.value=res.data.data.manageUnionList
        }
      })
    }
    let texts=ref('加载中')
      // 加载中 暂无数据列表
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.userId
      console.log(translate.value)
      if(translate.value==1){
        Hcode.value=''
        ability.value=''
        // values.value=''
        input.value=''
        texts.value='加载中'
        // branches.value=6
        // currentPage.value=1
        list()
        own()
      }
    })
    onMounted(()=>{
      list()
      // 所有组织列表接口
      own()
    })
     // 时间转换
    let tiems=(row, colum,cellValue )=>{
    if(typeof cellValue=='number'){
      return moments(cellValue*1000).format("YYYY-MM-DD")
    }
    }
    let reach=(row, colum,cellValue )=>{
      // console.log(row)
      // console.log(colum)
      // console.log(cellValue)
      let ASD=ref('')
      ASD.value=moments(row.startTime*1000).format("YYYY-MM-DD")+' - '+moments(row.endTime*1000).format("YYYY-MM-DD")
      return ASD.value
      // if(typeof cellValue=='number'){
      //   return moments(cellValue*1000).format("YYYY-MM-DD")
      // }
    }
    // 分页处理
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(input.value!==''|| ability.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(input.value!==''|| ability.value!==''){
        search()
      }else{
        list()
      }
    }
    // 数据增加
    let branches=ref(6)
    // 数据总数
    let totality =ref(null)
    // 获取人物列表
    let list =()=>{
      let data={
        limit:branches.value,
        page:currentPage.value,
      }
      ZNapply(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          // 列表总数
          totality.value=res.data.data.childCustodyList.totalCount
          // 列表数据
          filterTableData.value=res.data.data.childCustodyList.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 编辑跳转
    const copyreader = (id)=>{
      console.log(id)
      sessionStorage.setItem('ZNBMredact', id)
      router.push({ name: 'ZNBMredact', params: { UserId: 1 }});
    }
    // 外部工会管理跳转
    const exterior=(id)=>{
      console.log(id)
      sessionStorage.setItem('ZNexterior', id)
      router.push({ name: 'ZNexterior', params: { UserId: 1 }});
    }
    // 班级管理跳转
    const inventory=(id)=>{
      sessionStorage.setItem('ZNclasswide', id)
      router.push({ name: 'ZNclasswide', params: { userId: 1 }});
    }
    // 查看报名跳转
    const check=(id)=>{
      sessionStorage.setItem('ZNexamine', id)
      router.push({ name: 'ZNexamine', params: { userId: 1 }});
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      router.push('/ZNadd')
    }
    // 列表数据循环
    const filterTableData = ref([])
    return {
      rocket,
      dimensional,
      scanning,
      // 外部工会跳转
      exterior,
      reach,
      // 班级管理跳转
      inventory,
      // 子女查看跳转
      check,
      // 往期回顾跳转
      review,
      // 搜索加载
      search1,
      formative,
      // 类型
      // activity,
      // 判断类型
      genre,
      // 是否启用
      // values,
      formatSex,
      unit,
      selectIt,
      closeIt,
      affirm,
      // 删除弹窗
      remove,
      // 删除按钮
      expurgate,
      numerical2,
      // numerical1,
      // numerical2,
      // orangutan,
      // 标题内容
      input,
      // 搜索按钮
      search,
      // 搜索框
      ability,
      dendrogram,
      visible,
      multiple,
      defaultProps,
      texture,
      // 分页处理
      handleCurrentChange,
      handleSizeChange,
      currentPage,
      pagesize,
      // 加载中 暂无数据
      texts,
      tiems,
      // 列表总数
      totality,
      // 编辑跳转
      copyreader,
      // 头部组织添加跳转
      append,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    Delete,
    EditPen,
    Search,
    Plus,
    Setting,
	Plus,
  },
};
</script>
<style lang="scss" scoped>
.erwei{
  width: 16px;
  margin-right: 4px;
}
.green {
  color: #67c23a;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
  --el-button-active-bg-color:none;
}
.green:hover {
  color: #b3e19d;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-dialog{
  width: 40%;
}
.imagess{
  width: 150px;
}
.pussy{
  font-size: 22px;
  font-weight: bold;
}
.arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
::v-deep .el-dialog__body{
  display: flex;
  align-content: center;
  justify-content: center;
}
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
.summation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 150px);
  overflow: auto;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  // margin-bottom: 15px;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 20%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
// ::v-deep .el-scrollbar__thumb {
//   display: none !important;
// }
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
